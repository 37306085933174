import { usePageContext } from '@hooks/usePageContext';

import { NavigationLink } from './NavigationLink';
import { NavigationSelect } from './NavigationSelect';

export const NavigationRoutes: React.FC<{}> = () => {
  const { navigation = {} } = usePageContext();
  const { sectors, services, news, discover_us } = navigation;

  const routes = Object.values({
    sectors,
    services,
    news,
    discover_us,
  }).map((route) => route);

  return routes?.map((route: any) => {
    const { href, label, items } = route || {};

    if (typeof items !== 'undefined') {
      return <NavigationSelect key={label} label={label} items={items} />;
    }

    if (!href) {
      return null;
    }

    return <NavigationLink key={label} href={href} label={label} />;
  });
};
