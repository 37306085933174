import { ReactNode } from 'react';

import clsx from 'clsx';
import { match } from 'ts-pattern';

import { Link } from './Link';

interface ButtonProps {
  children: string | ReactNode;
  href?: string;
  variant?: 'primary' | 'secondary';
  size?: 'large' | 'medium' | 'small';
  icon?: React.ReactNode;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  children: label,
  href,
  variant = 'primary',
  icon,
  size,
  className,
}) => {
  const ButtonComponent = href ? Link : 'button';
  const sizeClass = match(size)
    .with('small', () => 'px-3 py-1.5 h-8 text-xs')
    .with('large', () => 'px-5 py-3 h-12')
    .otherwise(() => 'px-4 py-2.5 h-10 text-sm');

  return (
    <ButtonComponent
      className={clsx(
        'flex cursor-pointer select-none items-center justify-center whitespace-nowrap rounded-md text-center font-bold',
        variant === 'primary' &&
          'bg-primary-500 text-white hover:bg-primary-600 active:bg-primary-600 active:text-primary-200 disabled:bg-primary-300',
        variant === 'secondary' &&
          'border border-neutral-200 bg-white text-zinc-800 hover:border-neutral-300 hover:bg-neutral-50 active:border-neutral-300 active:text-neutral-500',
        sizeClass,
        className,
      )}
      href={href}
    >
      {label}
      {icon}
    </ButtonComponent>
  );
};
