export const extractMarkdown = (field?: Markdown | string) => {
  if (typeof field === 'string') {
    return field;
  }

  const { data } = field || {};

  return wrapLiContentWithSpan(data?.childMarkdownRemark?.html || '');
};

/**
 *  Wraps the content of each <li> element in a given HTML string with a <span> with specific class attributes
 *  Allows to have a single tag for styling
 *
 *  @param htmlString - the HTML string to be processed
 *  @returns a new HTML string with <li> contents wrapped in a <span>
 */
const wrapLiContentWithSpan = (htmlString: string): string => {
  // TODO: fix mismatch with server
  if (typeof window === 'undefined') {
    return htmlString;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const listItems = doc.querySelectorAll('li');

  listItems.forEach((li) => {
    const span = doc.createElement('span');

    // Move the existing contents of <li> into the <span>
    while (li.firstChild) {
      span.appendChild(li.firstChild);
    }

    // Append the <span> to the <li>
    li.appendChild(span);
  });

  return doc.body.innerHTML;
};
