import { useCallback, useState } from 'react';

import { match } from 'ts-pattern';

import { FormContext } from './FormContext';

enum FormState {
  FORM_INITIAL = 0,
  FORM_SUCCESS = 1,
  FORM_ERROR = 2,
}

interface FormProps {
  children: React.ReactNode;
  onSubmit: (data: any) => Promise<void>;
  className?: string;
  translations?: {
    optionalLabel?: string;
  };
  success?: React.ReactNode;
}

export const Form: React.FC<FormProps> = ({
  onSubmit,
  className,
  translations,
  children,
  success,
}) => {
  const [formState, setFormState] = useState(FormState.FORM_INITIAL);
  const [formError, setFormError] = useState(null);
  const finalOnSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const formData = new FormData(e.target);
      const formProps = Object.fromEntries(formData);

      return onSubmit(formProps)
        .then(() => setFormState(FormState.FORM_SUCCESS))
        .catch((error) => {
          setFormState(FormState.FORM_ERROR);
          setFormError(error);
        });
    },
    [onSubmit],
  );

  if (formState === FormState.FORM_SUCCESS && success) {
    return success;
  }

  return (
    <FormContext.Provider
      value={{
        translations,
        error: formError,
      }}
    >
      <form className={className} onSubmit={finalOnSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  );
};
