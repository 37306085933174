import { usePageContext } from '@hooks/usePageContext';

import MailIcon from '@assets/icons/mail.svg';

import { Button } from './Button';
import { Form } from './Form';
import { FormInput } from './Form/FormInput';
import { Typography } from './Typography';

export const FooterForm: React.FC<{}> = () => {
  const { navigation = {} } = usePageContext();
  const { footer_form_redirection } = navigation;

  return (
    <Form
      className="hidden flex-1 flex-col gap-6 lg:flex"
      onSubmit={(data) => {
        // return Promise.reject('Erreur: description claire de l’erreur');
        return Promise.resolve();
      }}
    >
      <Typography className="text-sm leading-tight text-zinc-800 md:whitespace-nowrap">
        {navigation?.footer_email_description}
      </Typography>

      <div
        className="flex items-center gap-2"
        onClick={() =>
          footer_form_redirection?.href &&
          window.open(footer_form_redirection.href, '_blank').focus()
        }
      >
        <FormInput
          type="email"
          name="email"
          className="flex-1"
          data={{
            placeholder: navigation?.footer_email_placeholder,
          }}
          required
        />
        <Button className="gap-1.5">
          {navigation?.footer_email_button}
          <MailIcon />
        </Button>
      </div>
    </Form>
  );
};
