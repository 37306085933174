import { useEffect } from 'react';

import { PageProps, navigate } from 'gatsby';

import { usePageContext } from '@hooks/usePageContext';

import { Footer } from '@components/Footer';
import { Header } from '@components/Header';
import { PageContext } from '@components/PageContext';

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'en';
  }

  const lang =
    navigator && navigator.language && navigator.language.split('-')[0];
  if (!lang) return 'en';

  switch (lang) {
    case 'fr':
      return 'fr';
    default:
      return 'en';
  }
};

const buildRedirectUrl = (urlLang: string, context: object) => {
  const isNonEnglish = urlLang !== 'en';
  const baseHref = context?.languageSwitchRoute?.href;

  if (!baseHref) {
    return null
  }

  return isNonEnglish ? `/${urlLang}${baseHref}` : baseHref;
};

export const Layout: React.FC<PageProps> = ({
  children,
  pageContext,
  location,
  path,
  uri,
}) => {
  useEffect(() => {
    const urlLang = getRedirectLanguage();

    if (urlLang !== pageContext?.locale) {
      const hasTranslatedEquivalent = buildRedirectUrl(urlLang, pageContext)

      if (!hasTranslatedEquivalent) {
        return
      }
      
      navigate(buildRedirectUrl(urlLang, pageContext), { replace: true });
    }
  }, []);

  return (
    <PageContext.Provider value={{ ...pageContext, location, path, uri }}>
      <div
        id="layout"
        data-locale={pageContext.locale}
        className="mx-auto flex h-full flex-col overflow-x-hidden text-neutral-700"
      >
        <Header />
        <main className="flex-1">{children}</main>
        <Footer />
      </div>
    </PageContext.Provider>
  );
};
