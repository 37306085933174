import { useCallback, useState } from 'react';

import clsx from 'clsx';

interface TextAreaInputProps {
  name: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  autoComplete?: string;
}

export const TextAreaInput: React.FC<TextAreaInputProps> = ({
  name,
  placeholder,
  required,
  className,
  autoComplete,
}) => {
  const [count, setCount] = useState(0);
  const updateCount = useCallback(
    (e) => setCount(e.target.value.length),
    [setCount],
  );

  return (
    <div className="relative flex flex-1">
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        required={required}
        className={clsx('flex-1 resize-none pr-20', className)}
        rows={6}
        maxLength={300}
        onChange={updateCount}
      />
      <span
        className="absolute right-3 top-2 text-xs
text-neutral-500"
      >
        {count} / 300
      </span>
    </div>
  );
};
