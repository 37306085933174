import clsx from 'clsx';
import { graphql } from 'gatsby';
import { match } from 'ts-pattern';

import { EmailInput } from './EmailInput';
import { TelInput } from './TelInput';
import { TextAreaInput } from './TextAreaInput';
import { useFormContext } from './useFormContext';

interface FormInputProps {
  type?: 'text' | 'textarea' | 'email' | 'tel';
  data?: {
    label?: string;
    placeholder?: string;
  };
  name: string;
  required?: boolean;
  autoComplete?: string;
  className?: string;
}

export const FormInput: React.FC<FormInputProps> = ({
  type = 'text',
  data,
  name,
  required,
  autoComplete,
  className,
}) => {
  const { translations } = useFormContext();
  const inputClassName = clsx(
    'flex items-center justify-start rounded-md border border-neutral-200 bg-white pl-3 py-2 shadow text-sm placeholder:text-sm placeholder:leading-6 placeholder:text-neutral-400 outline-none placeholder-shown:text-ellipsis invalid:border-red-600 invalid:placeholder-shown:border-neutral-200',
    !required && 'pr-20',
    className,
  );

  return (
    <fieldset>
      <label
        htmlFor={name}
        className="relative flex flex-col-reverse justify-end gap-1"
      >
        {match(type)
          .with('textarea', () => (
            <TextAreaInput
              name={name}
              placeholder={data?.placeholder}
              required={required}
              className={inputClassName}
              autoComplete={autoComplete}
            />
          ))
          .with('email', () => (
            <EmailInput
              name={name}
              placeholder={data?.placeholder}
              required={required}
              className={inputClassName}
            />
          ))
          .with('tel', () => (
            <TelInput
              name={name}
              placeholder={data?.placeholder}
              required={required}
              className={inputClassName}
            />
          ))
          .otherwise(() => (
            <input
              id={name}
              type={type}
              name={name}
              placeholder={data?.placeholder}
              required={required}
              className={inputClassName}
              autoComplete={autoComplete}
            />
          ))}

        {data?.label && (
          <span className="text-sm font-bold text-neutral-900">
            {data?.label}
          </span>
        )}

        {!required && (
          <span className="absolute bottom-0 right-3 flex h-[2.375rem] items-center text-sm text-neutral-500">
            ({translations?.optionalLabel})
          </span>
        )}
      </label>
    </fieldset>
  );
};
