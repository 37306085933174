import clsx from 'clsx';

interface DividerProps {
  color: 'neutral-200' | 'neutral-300';
  variant: 'horizontal' | 'vertical';
  className?: string;
}

export const Divider: React.FC<DividerProps> = ({
  color,
  variant,
  className,
}) => {
  return (
    <div
      className={clsx(
        `bg-${color}`,
        variant === `horizontal` && `w-full border-b border-b-${color}`,
        variant === `vertical` && `h-full border-l border-l-${color}`,
        className,
      )}
    />
  );
};
