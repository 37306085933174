import { ReactNode, memo } from 'react';

import clsx from 'clsx';
import { match } from 'ts-pattern';

import { extractMarkdown } from '@utils/markdown';

interface TypographyProps {
  children: Markdown | ReactNode;
  variant?:
    | 'body1'
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
    | 'subtitle3';
  className?: string;
  markdown?: boolean;
}

export const Typography: React.FC<TypographyProps> = memo(
  ({ variant, markdown, className, children }) => {
    const text = (
      markdown ? (
        <span
          className="markdown"
          dangerouslySetInnerHTML={{
            __html: extractMarkdown(children as Markdown),
          }}
        />
      ) : (
        children
      )
    ) as ReactNode;

    return match(variant)
      .with('overline', () => (
        <span
          className={clsx(
            'text-xs font-bold uppercase leading-tight tracking-wide md:text-[0.7rem] lg:text-sm',
            className,
          )}
          data-md={markdown}
        >
          {text}
        </span>
      ))
      .with('h1', () => (
        <h1
          className={clsx(
            'text-4xl font-bold leading-[2.75rem] text-neutral-900',
            className,
          )}
          data-md={markdown}
        >
          {text}
        </h1>
      ))
      .with('h2', () => (
        <h2 className={clsx(className)} data-md={markdown}>
          {text}
        </h2>
      ))
      .with('h3', () => (
        <h3
          className={clsx(
            'text-[2rem] font-bold leading-10 text-neutral-900',
            className,
          )}
          data-md={markdown}
        >
          {text}
        </h3>
      ))
      .with('h4', () => (
        <h4
          className={clsx('text-2xl font-bold text-neutral-900', className)}
          data-md={markdown}
        >
          {text}
        </h4>
      ))
      .with('h5', () => (
        <h5
          className={clsx(
            'text-xl font-bold leading-7 text-neutral-900',
            className,
          )}
          data-md={markdown}
        >
          {text}
        </h5>
      ))
      .with('h6', () => <h6>{text}</h6>)
      .with('subtitle1', () => (
        <span
          className={clsx('text-lg leading-7', className)}
          data-md={markdown}
        >
          {text}
        </span>
      ))
      .with('subtitle2', () => (
        <span
          className={clsx('text-lg leading-8 text-neutral-500', className)}
          data-md={markdown}
        >
          {text}
        </span>
      ))
      .with('subtitle3', () => (
        <span
          className={clsx('text-sm leading-tight text-neutral-500', className)}
          data-md={markdown}
        >
          {text}
        </span>
      ))
      .with('body1', () => (
        <p className={clsx(className)} data-md={markdown}>
          {text}
        </p>
      ))
      .otherwise(() => (
        <span className={clsx(className)} data-md={markdown}>
          {text}
        </span>
      ));
  },
);
