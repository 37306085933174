import { useCallback, useEffect, useState } from 'react';

import { debounce } from 'lodash';

import { usePageContext } from '@hooks/usePageContext';

import { Badge } from './Badge';
import { Container } from './Container';
import { Link } from './Link';
import { Logo } from './Logo';
import { NavigationDesktop } from './Navigation/NavigationDesktop';
import { NavigationMobile } from './Navigation/NavigationMobile';
import { Spacer } from './Spacer';

export const Header: React.FC<{}> = () => {
  const { navigation = {} } = usePageContext();
  const { home = {}, we_recruit = {} } = navigation;

  const [scrolled, setScrolled] = useState(false);

  const updateScroll = useCallback(() => {
    requestAnimationFrame(() => {
      const body = document.querySelector('body');
      const container = document.documentElement;

      if (!body || !container) {
        return;
      }

      setScrolled(
        !body.classList.contains('overflow-hidden') && container.scrollTop > 56,
      );
    });
  }, [setScrolled]);
  const debouncedUpdate = debounce(updateScroll, 100, { maxWait: 300 });

  useEffect(() => {
    const container = document.documentElement;
    if (!container) {
      return;
    }

    container.addEventListener('scroll', debouncedUpdate);
    container.addEventListener('touchend', debouncedUpdate);

    return () => {
      container.removeEventListener('scroll', debouncedUpdate);
      container.removeEventListener('touchend', debouncedUpdate);
    };
  }, [debouncedUpdate]);

  return (
    <div className="mb-20">
      <header
        className="fixed inset-x-0 top-0 z-20 flex min-h-[3.5rem] flex-1 items-center bg-white/[.85] backdrop-blur transition-shadow duration-300 data-[scrolled=true]:shadow-xl md:h-16 lg:min-h-[4.5rem]"
        data-scrolled={scrolled}
      >
        <Container className="flex w-full items-center">
          <Link href={home.href} className="py-3">
            <Logo />
          </Link>

          <div className="hidden md:flex">
            <Badge href={we_recruit.href} label={we_recruit.label} />
          </div>

          <Spacer />

          <div className="hidden h-11 items-center lg:flex">
            <NavigationDesktop />
          </div>

          <div className="flex lg:hidden">
            <NavigationMobile onMenuOpen={setScrolled} />
          </div>
        </Container>
      </header>

      <div id="nav-mobile" className="lg:hidden" />
    </div>
  );
};
