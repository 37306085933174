import { CSSProperties } from 'react';

import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface ImageProps {
  src?: string | object;
  className?: string;
  title?: string;
  fit?: CSSProperties['objectFit'];
}

export const Image: React.FC<ImageProps> = ({
  src,
  className,
  title = '',
  fit = 'cover',
}) => {
  const imageSharp = getImage(src?.localFile);

  return imageSharp ? (
    <GatsbyImage
      image={imageSharp}
      className={clsx('h-full w-full', className)}
      alt={title}
      imgStyle={{ objectFit: fit }}
    />
  ) : (
    <img
      src={src?.localFile?.publicURL ?? src?.url ?? src}
      className={clsx('h-full w-full', className)}
      title={title}
      draggable={false}
    />
  );
};
