import { createContext } from 'react';

interface PageContext {
  locale: string;
  navigation?: {
    home?: NavigationNode;
    sectors?: NavigationNode;
    services?: NavigationNode;
    startups?: NavigationNode;
    news?: NavigationNode;
    discover_us?: NavigationNode;
    contact?: NavigationNode;
    we_recruit?: NavigationNode;
    social?: NavigationNode;
    legal_terms?: NavigationNode;
    privacy_policy?: NavigationNode;
    footer?: NavigationNode;
    footer_email_description?: string;
    footer_email_placeholder?: string;
    footer_email_button?: string;
    footer_copyright?: string;
  };
  uri: string;
  languageSwitchRoute: NavigationNode;
  parent?: string;
  entity?: {
    [key: string]: any;
  };
  relatedEntities?: { [key: string]: any }[];
}

export const PageContext = createContext<PageContext>({} as PageContext);
