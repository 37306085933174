import { createContext } from 'react';

interface FormContext {
  translations?: {
    optionalLabel?: string;
  };
  error?: string;
}

export const FormContext = createContext({} as FormContext);
