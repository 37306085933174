import { useContext } from 'react';

import { PageContext } from '@components/PageContext';

const usePageContext = () => {
  const context = useContext(PageContext);

  if (!context) {
    throw new Error(
      'usePageContext must be used within a PageContext.Provider',
    );
  }

  return context;
};

export { usePageContext };
