import clsx from 'clsx';

import { Link } from '../Link';

export type NavigationLinkProps = {
  href: string;
  label: string;
  className?: string;
};

export const NavigationLink: React.FC<NavigationLinkProps> = ({
  href,
  label,
  className,
}) => (
  <Link
    href={href}
    className={clsx(
      'whitespace-nowrap py-3 font-bold text-neutral-700 hover:text-primary-400 lg:py-1',
      className,
    )}
  >
    {label}
  </Link>
);
