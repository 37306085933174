import { usePageContext } from '@hooks/usePageContext';

import FrenchFlag from '@assets/icons/Country-FR.svg';
import EnglishFlag from '@assets/icons/Country-GB.svg';

import { Link } from './Link';

const FlagMap = {
  en: EnglishFlag,
  fr: FrenchFlag,
};

const LocaleMap = {
  en: 'fr',
  fr: 'en',
};

export const LanguageSwitch: React.FC<{}> = () => {
  const { locale, languageSwitchRoute } = usePageContext();
  const Flag = FlagMap?.[LocaleMap?.[locale]];

  return (
    <Link
      href={languageSwitchRoute?.href}
      locale={languageSwitchRoute?.locale}
      className="language-switch flex cursor-pointer items-center text-neutral-900 hover:text-opacity-75"
    >
      {Flag && <Flag />}

      <span className="ml-2 text-sm font-bold uppercase">
        {LocaleMap?.[locale]}
      </span>
    </Link>
  );
};
