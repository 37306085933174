import { usePageContext } from '@hooks/usePageContext';

import { Badge } from './Badge';
import { Container } from './Container';
import { Divider } from './Divider';
import { FooterForm } from './FooterForm';
import { Image } from './Image';
import { Link } from './Link';
import { Logo } from './Logo';
import { Typography } from './Typography';

export const Footer: React.FC<{}> = () => {
  const { navigation = {} } = usePageContext();
  const {
    home = {},
    footer = [],
    we_recruit = {},
    social = [],
    legal_terms = {},
    privacy_policy = {},
    footer_copyright,
  } = navigation;

  return (
    <footer className="footer flex justify-center gap-16 bg-neutral-50 py-16">
      <Container className="flex flex-1 flex-col gap-12">
        <div className="flex flex-1 flex-wrap justify-start gap-8 lg:gap-20">
          <div className="flex flex-1 flex-col gap-8">
            <Link href={home.href} className="py-3">
              <Logo />
            </Link>

            <div className="flex flex-col flex-wrap items-start gap-6 md:flex-row md:items-center">
              {footer?.map((el: NavigationNode, index) => (
                <div key={el.href} className="flex items-center gap-2">
                  <Link
                    href={el.href}
                    className="whitespace-nowrap text-sm font-bold leading-tight text-neutral-500 hover:text-primary-400"
                  >
                    {el.label}
                  </Link>

                  {index === footer.length - 1 && (
                    <Badge href={we_recruit?.href} label={we_recruit?.label} />
                  )}
                </div>
              ))}
            </div>
          </div>

          <FooterForm />
        </div>

        <Divider variant="horizontal" color="neutral-200" />

        <div className="flex flex-wrap-reverse gap-5 md:flex-wrap">
          <div className="flex flex-1 items-center justify-center gap-4 px-4 md:justify-start md:px-0">
            {social?.map((el: NavigationNode) => (
              <Link
                key={el.href}
                href={el.href}
                className="flex-shrink-0 hover:text-primary-400"
              >
                <Image src={el.icon} />
              </Link>
            ))}
          </div>

          <div className="flex min-w-full flex-1 flex-wrap justify-center gap-8 px-4 sm:whitespace-nowrap md:ml-auto md:min-w-0 md:flex-initial md:px-0">
            <Link
              href={legal_terms?.href}
              className="hidden text-sm leading-tight text-neutral-500 hover:text-primary-400 md:flex"
            >
              {legal_terms?.label}
            </Link>

            <Link
              href={privacy_policy?.href}
              className="hidden text-sm leading-tight text-neutral-500 hover:text-primary-400 md:flex"
            >
              {privacy_policy?.label}
            </Link>

            <Typography className="flex-1 text-center text-sm leading-tight text-neutral-500 md:hidden lg:flex">
              {footer_copyright}
            </Typography>
          </div>
        </div>
      </Container>
    </footer>
  );
};
