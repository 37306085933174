import { Link } from './Link';

interface BadgeProps {
  label: string;
  href?: string;
}

export const Badge: React.FC<BadgeProps> = ({ label, href }) => {
  const Wrapper = href ? Link : 'div';

  return (
    <Wrapper
      href={href}
      className="flex h-6 select-none items-center justify-center whitespace-nowrap rounded-3xl bg-primary-50 px-[0.625rem] py-[0.125rem] text-[0.75rem] font-bold leading-tight text-red-700 hover:text-primary-400"
    >
      {label}
    </Wrapper>
  );
};
