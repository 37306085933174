import { ReactNode } from 'react';

import clsx from 'clsx';

interface ContainerProps {
  className?: string;
  children: ReactNode;
  spaced?: boolean;
  centered?: boolean;
}

export const Container: React.FC<ContainerProps> = ({
  className,
  children,
  spaced = true,
  centered = true,
}) => (
  <div
    className={clsx(
      'max-w-full lg:max-w-screen-lg',
      spaced && 'px-8 xl:px-0',
      centered && 'mx-auto',
      className,
    )}
  >
    {children}
  </div>
);
