interface EmailInputProps {
  name: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
}

export const EmailInput: React.FC<EmailInputProps> = ({
  placeholder,
  name,
  className,
  required,
}) => (
  <input
    type="email"
    id={name}
    name={name}
    className={className}
    placeholder={placeholder}
    pattern="^\S+@\S+\.\S+$"
    required={required}
    autoComplete="email"
  />
);
