import { usePageContext } from '@hooks/usePageContext';

import { LanguageSwitch } from '@components/LanguageSwitch';

import { Button } from '../Button';
import { Divider } from '../Divider';
import { NavigationRoutes } from './NavigationRoutes';

export const NavigationDesktop: React.FC<{}> = () => {
  const { navigation } = usePageContext();

  return (
    <>
      <nav className="mx-8 hidden items-center gap-6 md:flex">
        <NavigationRoutes />
      </nav>

      <Divider variant="vertical" color="neutral-300" />

      <div className="mx-8">
        <LanguageSwitch />
      </div>

      <Button size="medium" href={navigation?.contact?.href}>
        {navigation?.contact?.label}
      </Button>
    </>
  );
};
