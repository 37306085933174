import { Link as GatsbyLink } from 'gatsby';
import { match } from 'ts-pattern';

import { usePageContext } from '@hooks/usePageContext';

export type SharedProps = {
  href: string;
  className?: string;
  locale?: string;
};

export type LinkWithLabel = SharedProps & {
  label: string;
  children?: never;
};

export type LinkWithChildren = SharedProps & {
  label?: never;
  children: React.ReactNode;
};

export type LinkProps = LinkWithLabel | LinkWithChildren;

export const Link: React.FC<LinkProps> = ({
  href,
  label,
  className,
  children,
  locale: forcedLocale,
}) => {
  const { locale } = usePageContext();

  const finalLocale = forcedLocale || locale;
  const isExternal = !!(href && href.startsWith('http'));
  const finalHref = isExternal
    ? href
    : match(finalLocale)
        .with('en', () => {
          return href;
        })
        .otherwise(() => {
          if (!href) {
            return '';
          }

          return href.startsWith('#')
            ? `/${finalLocale}/${href}`
            : `/${finalLocale}${href}`;
        });

  return match(isExternal)
    .with(true, () => (
      <a href={finalHref} className={className}>
        {label || children}
      </a>
    ))
    .with(false, () => (
      <GatsbyLink
        to={finalHref}
        className={className}
        activeClassName="text-primary-400"
        aria-disabled={!finalHref}
      >
        {label || children}
      </GatsbyLink>
    ))
    .exhaustive();
};
