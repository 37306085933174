import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid';
import { globalHistory } from '@reach/router';

import { usePageContext } from '@hooks/usePageContext';

import { LanguageSwitch } from '@components/LanguageSwitch';

import { Button } from '../Button';
import { Divider } from '../Divider';
import { NavigationRoutes } from './NavigationRoutes';

interface NavigationMobileProps {
  onMenuOpen: (value: boolean) => void;
}

export const NavigationMobile: React.FC<NavigationMobileProps> = ({
  onMenuOpen,
}) => {
  const { navigation } = usePageContext();
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen((open: boolean) => {
      const body = document.querySelector('body');
      const container = document.documentElement;

      if (!body || !container) {
        return open;
      }

      body.classList.toggle('overflow-hidden');

      // disable shadow effect when menu open
      onMenuOpen(open ? container.scrollTop > 56 : open);

      return !open;
    });
  }, [onMenuOpen, setOpen]);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        const body = document.querySelector('body');
        const container = document.documentElement;

        setOpen(false);

        if (body) {
          body.classList.remove('overflow-hidden');
        }

        if (container) {
          onMenuOpen(false);
        }
      }
    });
  }, [setOpen]);

  return (
    <>
      <button
        className="rounded-md border border-neutral-200 bg-white p-2 text-neutral-500 shadow active:bg-neutral-50 active:text-neutral-300"
        onClick={toggleOpen}
      >
        {!open ? (
          <Bars3Icon className="h-5 w-5" />
        ) : (
          <XMarkIcon className="h-5 w-5" />
        )}
      </button>

      {open &&
        createPortal(
          <div className="fixed inset-x-0 inset-y-0 z-10 flex flex-col gap-4 overflow-auto bg-white px-8 pb-4 pt-20 lg:top-[4.5rem]">
            <nav className="flex flex-col gap-1">
              <NavigationRoutes />
            </nav>
            <div className="my-4">
              <LanguageSwitch />
            </div>
            <Divider variant="horizontal" color="neutral-300" />

            <Button href={navigation?.contact?.href}>
              {navigation?.contact?.label}
            </Button>
          </div>,
          document.getElementById('nav-mobile'),
        )}
    </>
  );
};
