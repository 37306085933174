import { useCallback, useState } from 'react';

import UpIcon from '@assets/icons/cheveron-up.svg';

import { NavigationLink, NavigationLinkProps } from './NavigationLink';

type NavigationSelectProps = {
  label: string;
  items: NavigationLinkProps[];
};

// TODO: see for mobile navigation select using details/summary
export const NavigationSelect: React.FC<NavigationSelectProps> = ({
  label,
  items,
}) => {
  const [visible, setVisible] = useState(false);
  const toggleVisible = useCallback(() => {
    setVisible((value) => !value);
  }, [setVisible]);
  const handleMouseEnter = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handleMouseLeave = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="flex cursor-pointer select-none items-center whitespace-nowrap py-3 text-neutral-700 hover:text-primary-400 focus:text-red-400 data-[visible=true]:text-primary-400"
        data-visible={visible}
        onTouchEnd={toggleVisible}
      >
        <span className="font-bold">{label}</span>

        <UpIcon
          className="ml-2 transition-transform duration-200 data-[visible=false]:-rotate-180"
          data-visible={visible}
        />
      </div>

      <div
        className="pointer-events-none z-20 flex h-0 min-w-[18rem] flex-col gap-1 border-gray-100 bg-white opacity-0 transition-all duration-200 data-[visible=true]:pointer-events-auto data-[visible=true]:h-auto data-[visible=true]:opacity-100 lg:absolute lg:h-auto lg:rounded-lg lg:border lg:py-4 lg:shadow-lg"
        data-visible={visible}
        onClick={toggleVisible}
      >
        {items?.map((item, index) => (
          <NavigationLink
            key={index}
            href={item.href}
            label={item.label}
            className="px-2 text-sm font-normal lg:px-6 lg:text-base lg:font-bold"
          />
        ))}
      </div>
    </div>
  );
};
