interface TelInputProps {
  name: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
}

export const TelInput: React.FC<TelInputProps> = ({
  placeholder,
  name,
  className,
  required,
}) => (
  <input
    type="tel"
    id={name}
    name={name}
    className={className}
    placeholder={placeholder}
    required={required}
    autoComplete="tel"
    pattern="^\+?(\s*\d){5,15}\s*$"
  />
);
